import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import EmptyLayout from '@/layout/empty'

Vue.use(VueRouter)

const constantRoutes = [
  {
    path: '/',
    component: Layout
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  }
]
// ..路由
const asyncRoutes = [
  {
    path: '/advertisement',
    component: Layout,
    meta: {
      title: '广告管理',
      icon: require('@/assets/menu/icon1.png'),
      id: 128
    },
    redirect: '/advertisement',
    children: [
      {
        path: '',
        name: 'advertisement',
        meta: {
          title: '广告列表',
          id: 132,
          sidebar: false,
          activeMenu: '/advertisement',
          parent_id: 128
        },
        component: () => import('@/views/advertisement/index.vue')
      },
      {
        path: '/advertisement/edit',
        name: 'advertisementEdit',
        meta: {
          title: '广告列表',
          id: 132,
          sidebar: false,
          activeMenu: '/advertisement',
          parent_id: 128
        },
        component: () => import('@/views/advertisement/addOrEdit.vue')
      }
    ]
  },
  {
    path: '/information',
    name: 'information',
    component: Layout,
    redirect: '/information/list',
    subset: true,
    meta: {
      title: '资讯管理',
      icon: require('@/assets/menu/icon2.png'),
      id: 135
    },
    children: [
      {
        path: 'list',
        name: 'list',
        redirect: '/information/list',
        component: EmptyLayout,
        meta: {
          title: '资讯列表',
          id: 136,
          parent_id: 135,
          hiddenBread: true // 控制面包屑的
        },
        children: [
          {
            path: '',
            name: 'disputeIndex',
            component: () => import('@/views/information/list.vue'),
            meta: {
              title: '资讯列表',
              id: 143,
              parent_id: 136,
              sidebar: false,
              activeMenu: '/information/list'
            }
          },
          {
            path: '/information/add',
            name: 'informationAdd',
            component: () => import('@/views/information/add.vue'),
            meta: {
              title: '新增资讯',
              id: 139,
              parent_id: 136,
              sidebar: false,
              activeMenu: '/information/list'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    meta: {
      title: '用户管理',
      icon: require('@/assets/menu/icon3.png'),
      id: 123
    },
    redirect: '/user',
    children: [
      {
        path: '',
        name: 'user',
        meta: {
          title: '用户列表',
          id: 124,
          sidebar: false,
          activeMenu: '/user',
          parent_id: 123
        },
        component: () => import('@/views/user/list.vue')
      }
    ]
  },
  // {
  //   path: '/works',
  //   name: 'works',
  //   component: Layout,
  //   redirect: '/works/list',
  //   meta: {
  //     title: '作品管理',
  //     icon: require('@/assets/images/icon1.png'),
  //     id: 146
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'list',
  //       component: EmptyLayout,
  //       meta: {
  //         title: '作品列表',
  //         id: 147,
  //         parent_id: 146
  //       },
  //       children: [
  //         {
  //           path: '',
  //           name: 'worksList',
  //           component: () => import('@/views/works/list.vue'),
  //           meta: {
  //             title: '登记列表',
  //             id: 19,
  //             parent_id: 18,
  //             sidebar: false,
  //             activeMenu: '/works/list'
  //           }
  //         },
  //         {
  //           path: '/works/detail',
  //           name: 'worksDetail',
  //           component: () => import('@/views/works/detail.vue'),
  //           meta: {
  //             title: '登记详情',
  //             id: 148,
  //             parent_id: 147,
  //             sidebar: false,
  //             activeMenu: '/works/list'
  //           }
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '/attestation',
    component: Layout,
    meta: {
      title: '认证管理',
      icon: require('@/assets/menu/icon5.png'),
      id: 210 // id等改
    },
    redirect: '/attestation',
    children: [
      {
        path: '',
        name: 'attestation',
        meta: {
          title: '认证列表',
          id: 211,
          parent_id: 210,
          sidebar: false,
          activeMenu: '/attestation'
        },
        component: () => import('@/views/attestation/list.vue')
      }
    ]
  },
  {
    path: '/works',
    component: Layout,
    meta: {
      title: '作品管理',
      icon: require('@/assets/menu/icon5.png'),
      id: 146
    },
    redirect: '/works',
    children: [
      {
        path: '',
        name: 'works',
        meta: {
          title: '作品管理',
          id: 147,
          parent_id: 146,
          sidebar: false,
          activeMenu: '/works'
        },
        component: () => import('@/views/works/list.vue')
      },
      {
        path: '/works/detail',
        name: 'worksDetail',
        meta: {
          title: '作品详情',
          id: 148,
          parent_id: 146,
          sidebar: false,
          activeMenu: '/works'
        },
        component: () => import('@/views/works/detail.vue')
      }
    ]
  },

  {
    path: '/range',
    name: 'range',
    component: Layout,
    // redirect: '/range',
    subset: true,
    meta: {
      title: '范围登记管理',
      icon: require('@/assets/menu/icon4.png'),
      id: 190
    },
    children: [
      {
        path: 'list',
        name: 'rangeList',
        redirect: '/range/list',
        component: EmptyLayout,
        meta: {
          title: '范围登记',
          id: 191,
          // sidebar: false,
          parent_id: 190,
          hiddenBread: true // 控制面包屑的
        },
        children: [
          {
            path: '',
            name: 'rangeIndex',
            component: () => import('@/views/range/list.vue'),
            meta: {
              title: '范围登记',
              id: 194,
              parent_id: 191,
              sidebar: false,
              activeMenu: '/range/list'
            }
          },
          {
            path: '/range/addEdit',
            name: 'rangeEdit',
            component: () => import('@/views/range/addEdit.vue'),
            meta: {
              title: '操作范围登记',
              id: 194,
              parent_id: 191,
              sidebar: false,
              activeMenu: '/range/list'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/payment',
    name: 'payment',
    component: Layout,
    // redirect: '/payment/list',
    subset: true,
    meta: {
      title: '支付管理',
      icon: require('@/assets/menu/icon6.png'),
      id: 151
    },
    children: [
      {
        path: 'list',
        name: 'paymentList',
        redirect: '/payment/list',
        component: EmptyLayout,
        meta: {
          title: '支付列表',
          id: 152,
          parent_id: 151,
          hiddenBread: true // true让面包屑消失
        },
        children: [
          {
            path: '',
            name: 'paymentList',
            component: () => import('@/views/payment/list.vue'),
            meta: {
              title: '支付列表',
              id: 178,
              parent_id: 152,
              sidebar: false,
              activeMenu: '/payment/list'
            }
          },
          {
            path: '/payment/edit',
            name: 'paymentEdit',
            component: () => import('@/views/payment/edit.vue'),
            meta: {
              title: '支付编辑',
              id: 154,
              parent_id: 152,
              sidebar: false,
              activeMenu: '/payment/list'
            }
          },
          {
            path: '/payment/add',
            name: 'paymentAdd',
            component: () => import('@/views/payment/add.vue'),
            meta: {
              title: '支付新增',
              id: 155,
              parent_id: 152,
              sidebar: false,
              activeMenu: '/payment/list'
            }
          }
        ]
      },
      {
        path: '/charge/list',
        name: 'chargeList',
        redirect: '/charge/list',
        component: EmptyLayout,
        meta: {
          title: '收费标准',
          id: 156,
          parent_id: 151,
          hiddenBread: true
        },
        children: [
          {
            path: '',
            name: 'chargeList',
            component: () => import('@/views/charge/list.vue'),
            meta: {
              title: '收费标准',
              id: 159,
              parent_id: 156,
              sidebar: false,
              activeMenu: '/charge/list'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/helpCenter',
    component: Layout,
    subset: true,
    meta: {
      title: '帮助中心',
      icon: require('@/assets/menu/icon7.png'),
      id: 95
    },
    redirect: '/helpCenter',
    children: [
      {
        path: '',
        name: 'helpCenter',
        meta: {
          title: '帮助列表',
          id: 116,
          parent_id: 95,
          sidebar: false,
          activeMenu: '/helpCenter'
        },
        component: () => import('@/views/helpCenter/list.vue')
      },
      {
        path: '/helpCenter/add',
        name: 'helpCenterAdd',
        meta: {
          title: '帮助操作',
          id: 97,
          parent_id: 95,
          sidebar: false,
          activeMenu: '/helpCenter'
        },
        component: () => import('@/views/helpCenter/add.vue')
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: Layout,
    // redirect: '/payment/list',
    subset: true,
    meta: {
      title: '系统管理',
      icon: require('@/assets/menu/icon8.png'),
      id: 7
    },
    children: [
      {
        path: 'contact',
        name: 'contact',
        redirect: '/system/contact',
        component: EmptyLayout,
        meta: {
          title: '联系方式',
          id: 162,
          parent_id: 7,
          hiddenBread: true
        },
        children: [
          {
            path: '',
            name: 'contactList',
            component: () => import('@/views/contact/list.vue'),
            meta: {
              title: '联系方式',
              id: 163,
              parent_id: 162,
              sidebar: false,
              activeMenu: '/system/contact'
            }
          }
        ]
      },
      {
        path: '/admin/list',
        name: 'admin',
        // redirect: '/admin/list',
        component: () => import('@/views/admin/list.vue'),
        meta: {
          title: '管理员列表',
          id: 34,
          parent_id: 7
        }
        // children: [
        //   {
        //     path: '',
        //     name: 'adminList',
        //     component: () => import('@/views/admin/list.vue'),
        //     meta: {
        //       title: '管理员列表',
        //       id: 19,
        //       parent_id: 34,
        //       sidebar: false,
        //       activeMenu: '/admin/list'
        //     }
        //   }
        // ]
      },
      {
        path: '/power/list',
        name: 'power',
        redirect: '/power/list',
        component: EmptyLayout,
        meta: {
          title: '权限管理',
          id: 29,
          parent_id: 7,
          hiddenBread: true
        },
        children: [
          {
            path: '',
            name: 'powerList',
            component: () => import('@/views/power/list.vue'),
            meta: {
              title: '权限管理',
              id: 175,
              parent_id: 29,
              sidebar: false,
              activeMenu: '/power/list'
            }
          },
          {
            path: '/power/roleIndex',
            name: 'roleIndex',
            component: () => import('@/views/power/roleIndex.vue'),
            meta: {
              title: '权限',
              id: 31,
              parent_id: 29,
              sidebar: false,
              activeMenu: '/power/list'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/operation',
    component: Layout,
    meta: {
      title: '操作日志',
      icon: require('@/assets/menu/icon9.png'),
      id: 121
    },
    redirect: '/operation',
    children: [
      {
        path: '',
        name: 'operation',
        meta: {
          title: '操作日志',
          id: 180,
          sidebar: false,
          activeMenu: '/operation',
          parent_id: 121
        },
        component: () => import('@/views/operation/index.vue')
      }
    ]
  }
]

// 解决路由在 push/replace 了相同地址报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
export { asyncRoutes, constantRoutes }

export default router
