/*
 * @Description: 权限
 *
 *  v-if="$globalFun.ifAuth($route, '新增')"
 *  console.log('ifAuth', this.$globalFun.ifAuth($route, '新增'))
 */

import store from '@/store'

/**
 * @description:  根据权限当前路由判断是否有权限（用于按钮）
 * @param {*} route 当前路由
 * @param {*} title 按钮名字
 * @return {*}
 */
export function ifAuth (route, title) {
  // if (store.state.settings.openPermission) {
  // 开启权限
  const permissions = store.state.menu.permissions.role

  // console.log('接口权限列表，metaparentid', permissions, route.meta.parent_id)
  const permissionsItem = findID(permissions, route.meta.parent_id)
  // console.log('permissionsItem', permissionsItem)

  return permissionsItem.children.some(item => {
    return item.name === title
  })
}

// 递归出是否存在，存在跳出递归
const findID = (permissions, id) => {
  for (let i = 0; i < permissions.length; i++) {
    const item = permissions[i]

    if (item.id === id) {
      // console.log('item', item)
      return item
    } else {
      if (item.children && item.children.length) {
        if (findID(item.children, id)) {
          return findID(item.children, id)
        }
      }
    }
  }
}

// 根据id
// export function ifAuth (id) {
//   const permissions = store.state.menu.permissions
//   // console.log('接口权限列表', permissions)
//   return findID(permissions, id)
// }

// // 递归出是否存在，存在跳出递归
// const findID = (permissions, id) => {
//   for (let i = 0; i < permissions.length; i++) {
//     const item = permissions[i]

//     if (item.id === id) {
//       return true
//     }

//     if (item.children && item.children.length) {
//       if (findID(item.children, id)) {
//         return true
//       }
//     }
//   }
// }
