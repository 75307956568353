<template>
  <div class="main">
    <div @click="onBack" class="left cursor-pointer">
      <i class="el-icon-back"></i>
    </div>
    <el-breadcrumb separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item v-for="item in breadList" :key="item.path">{{
        item.meta.title
      }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'breadIndex',
  data () {
    return {
      meta: {}
    }
  },
  created () {
    this.getList()
  },
  computed: {
    breadList () {
      const breadList = []
      // eslint-disable-next-line array-callback-return
      this.$route.matched.map((item) => {
        if (item.meta.title && !item.meta.hiddenBread) {
          breadList.push(item)
        }
      })
      return breadList
    }
  },
  methods: {
    async getList () {
      this.meta = this.$route.meta
    },
    onBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  // margin-left: 10px;
  padding: 14px 0;
  padding-left: 20px;
  background: #F4F8FE;
  display: flex;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    color: #fff;
    width: 18px;
    height: 18px;
    background: #507afd;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-right: 20px;
  }
}
</style>
