import http from '@/utils/http'
// 帮助中心
export default {
  // 列表
  getList: (data) => {
    return http({
      url: '/api/admin/question/index',
      data
    })
  },
  // 删除
  delete: (data) => {
    return http({
      url: '/api/admin/question/del',
      data
    })
  },
  // 保存
  onSave: (data) => {
    return http({
      url: '/api/admin/question/save',
      data
    })
  },
  // 详情
  getInfo: (data) => {
    return http({
      url: '/api/admin/question/info',
      data
    })
  },
  // 编辑
  onEdit: (data) => {
    return http({
      url: '/api/admin/question/edit',
      data
    })
  }
}
