import http from '@/utils/http'
// 权限管理
export default {
  // 角色列表
  getList: (data) => {
    return http({
      url: '/api/admin/role/index',
      data
    })
  },
  // 详情
  getInfo: (data) => {
    return http({
      url: '/api/admin/role/info',
      data
    })
  },
  // 编辑
  getEdit: (data) => {
    return http({
      url: '/api/admin/role/edit',
      data
    })
  },
  // 添加
  getAdd: (data) => {
    return http({
      url: '/api/admin/role/add',
      data
    })
  },
  // 删除
  delete: (data) => {
    return http({
      url: '/api/admin/role/del',
      data
    })
  },
  // 权限列表
  getPowerList: (data) => {
    return http({
      url: '/api/admin/role/power_list',
      data
    })
  }
}
