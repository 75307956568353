import http from '../utils/http'

// 登录
export function accountLogin (data) {
  return http({
    url: '/api/admin/account/login',
    data
  })
}
// 退出
export function accountLogout (data) {
  return http({
    url: '/api/admin/account/logout',
    data
  })
}
// 管理员权限
export function accountRole (data) {
  return http({
    url: '/api/admin/account/role',
    data
  })
}
