import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import VuePageStack from 'vue-page-stack'

import 'element-ui/lib/theme-chalk/index.css'

// 路由导航守卫
import './permission'
// api挂载
import api from '@/api'

import './styles/reset.css' // 样式重置表
import './styles/global.css' // 全局样式表

// import './styles/global.scss' // 全局样式表

// echarts
import * as echarts from 'echarts'

// 全局方法
import globalFun from './utils'

Vue.prototype.$api = api.module
Vue.config.productionTip = false
Vue.prototype.$globalFun = globalFun
Vue.prototype.$echarts = echarts

Vue.use(ElementUI)
Vue.use(VuePageStack, { router }) // 页面栈插件，能缓存页面

// 节流 用法 v-preventReClick="1000" 1000为节流时间ms
Vue.directive('preventReClick', {
  inserted (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true

        setTimeout(() => {
          el.disabled = false
        }, binding.value || 2000)
      }
    })
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
