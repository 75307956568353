import http from '@/utils/http'
// 管理员
export default {
  // 列表
  getList: (data) => {
    return http({
      url: '/api/admin/account/index',
      data
    })
  },
  // 删除
  delete: (data) => {
    return http({
      url: '/api/admin/account/del',
      data
    })
  },
  // 保存
  onSave: (data) => {
    return http({
      url: '/api/admin/account/save',
      data
    })
  },
  // 详情
  getInfo: (data) => {
    return http({
      url: '/api/admin/account/info',
      data
    })
  },
  // 编辑()
  onEdit: (data) => {
    return http({
      url: '/api/admin/account/edit',
      data
    })
  },
  // 新增
  onAdd: (data) => {
    return http({
      url: 'api/admin/account/add',
      data
    })
  },
  // 改密码
  changePd: (data) => {
    return http({
      url: 'api/admin/account/change_pd',
      data
    })
  },
  // 联系方式 详情
  contact: (data) => {
    return http({
      url: '/api/admin/account/contact_info',
      data
    })
  },
  // 联系方式 保存
  contactSave: (data) => {
    return http({
      url: '/api/admin/account/contact_save',
      data
    })
  },
  // 更改状态
  changeStatus: (data) => {
    return http({
      url: '/api/admin/account/change_status',
      data
    })
  }
}
