/*
 * @Author: LZH
 * @Date: 2023-02-14 11:18:16
 * @LastEditTime: 2024-03-08 10:47:03
 * @FilePath: \copyright\src\utils\http.js
 */
import axios from 'axios'
import {
  Message, MessageBox
} from 'element-ui'
import NProgress from 'nprogress'
import store from '../store'

// 环境的切换
// const url = 'https://gdcopyright-api-dev.ozkoalas.cn/' // 测试环境
const url = 'https://gdcopyright-api.ozkoalas.cn' // 正式环境

// console.log('NODE_ENV', process.env.NODE_ENV)
// development: 开发环境， production: 线上环境， test: 测试环境
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  axios.defaults.baseURL = url
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = url
}

// 创建axios的一个实例
const instance = axios.create({
  timeout: 15000, //  指定请求超时的毫秒数，如果请求超过 `timeout` 的时间，请求将被中断
  method: 'POST'
})

// 请求拦截器
instance.interceptors.request.use(config => {
  NProgress.start() // 进度条开启
  // 登陆后请求携带 token
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.token = token
  }
  return config
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(response => {
  NProgress.done() // 进度条关闭
  console.log('response', response)
  const res = response.data
  // 判断错误返回 false
  if (res.code && res.code !== 1) {
    Message({
      message: res.msg,
      type: 'error',
      duration: 3 * 1000
    })
    // 未登录/没有权限
    if (res.code === -90001 || res.code === -90002) {
      const title = res.code === -90001 ? '登录已过期' : '抱歉，您没有权限访问'
      MessageBox.alert(`${title}，请重新登录！`, '提示', {
        showClose: false,
        showCancelButton: res.code !== -90001
      }).then(() => {
        // 登出
        // store.dispatch('user/Logout', 'overdue')
        store.dispatch('user/Logout')
      })
    }
    return Promise.reject(res.msg)
  } else {
    return response.data
  }
  // if (res.code !== 1 && res.code !== -90002 && res.code !== -1) {
  //   Message({
  //     message: res.msg,
  //     type: 'error',
  //     duration: 3 * 1000
  //   })
  //   localStorage.removeItem("token")
  //   console.log('ree')
  //   window.location = '/#/login'
  //   return false

  // } else {
  //   return response.data
  // }
}, error => {
  // 对响应错误做点什么
  NProgress.done()

  console.log('err', error)

  // Message({
  //   message: error,
  //   type: 'error',
  //   duration: 3 * 1000
  // })
  return Promise.reject(error)
})

export default instance
