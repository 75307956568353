/*
 * @Description: 负责生成导航菜单的路由数据
 */
import { deepClone } from '@/utils'

const state = {
  isGenerate: false,
  // 一级导航栏
  headerActived: 0,
  // 过滤后的权限路由
  routes: [],
  // 接口获取路由权限
  permissions: []
}

const getters = {
  sidebarRoutes: (state) => {
    return state.routes
  }
}

const actions = {
  // 根据权限动态生成路由
  generateRoutes ({ rootState, dispatch, commit }, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const permissions = await dispatch('user/getPermissions', null, { root: true }) // ！！ 接口拿出来要匹配的路由
      if (!permissions) {
        this.$router.push('/')
      }
      // console.log('menujs..permissions', data.asyncRoutes, permissions)

      commit('setPermissions', permissions)
      let accessedRoutes = data.asyncRoutes // 传过来定义的权限路由！！

      accessedRoutes = await filterAsyncRoutes(data.asyncRoutes, permissions.role) //! !  两个路由匹配出！

      commit('setRoutes', accessedRoutes)
      commit('setHeaderActived', data.currentPath)

      // const routes = [];
      // accessedRoutes.map(item => {
      //   routes.push(...item.children)  没有多一层, 所以不需要这步
      // })
      // console.log('routacssses', accessedRoutes, routes)

      resolve(accessedRoutes) // 换试试
    })
  }
}

const mutations = {
  invalidRoutes (state) {
    state.isGenerate = false
    state.headerActived = 0
  },

  setPermissions (state, permissions) {
    state.permissions = permissions
  },

  // 存储路由
  setRoutes (state, routes) {
    state.isGenerate = true
    const newRoutes = deepClone(routes)
    state.routes = newRoutes.filter((item) => {
      return item.children.length !== 0
    })
  },

  // 根据路由判断属于哪个头部导航
  setHeaderActived (state, path) {
    // eslint-disable-next-line array-callback-return
    state.routes.map((item, index) => {
      if (
        item.children.some((r) => {
          return path.indexOf(r.path + '/') === 0 || path === r.path
        })
      ) {
        state.headerActived = index
      }
    })
  },

  // 切换头部导航
  switchHeaderActived (state, index) {
    state.headerActived = index
  },

  /**
   * @description 修改Meta
   * @param {*} state
   * @param options
   */
  changeMenuMeta (state, options) {
    function handleRoutes (routes) {
      return routes.map((route) => {
        if (route.name === options.name) {
          route.meta = Object.assign({}, route.meta, options.meta)
        }
        if (route.children && route.children.length) {
          route.children = handleRoutes(route.children)
        }
        return route
      })
    }

    state.routes = handleRoutes(state.routes)
  }
}

/**
 * @description: 权限匹配对应路由
 * @param {*} routes 本地路由
 * @param {*} permissions 接口获取到的权限路由
 * @return {*}
 */
//  总的  ，  接口的
function filterAsyncRoutes (routes, permissions) {
  const res = []
  routes.forEach((route) => {
    const tmp = { ...route }
    for (const item of permissions) {
      // 如果一层不匹配就不需要再继续进行了
      if (item.id === tmp.meta.id) { //  ！！！ 查相同id ！！！
        if (tmp.children) {
          // console.log('进来if里的', tmp.children)
          tmp.children = filterAsyncRoutes(tmp.children, item.children)
          tmp.children.length && res.push(tmp)
        } else {
          res.push(tmp)
        }
      }
    }
  })
  return res
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
