import http from '@/utils/http'
// 作品列表
export default {
  // 列表
  getList: (data) => {
    return http({
      url: '/api/admin/work/index',
      data
    })
  },
  // 作品类型列表
  getWorksType: (data) => {
    return http({
      url: 'api/admin/work/registration_scope_index',
      data
    })
  },
  // 删除
  delete: (data) => {
    return http({
      url: '/api/admin/work/del',
      data
    })
  },
  // 保存
  onSave: (data) => {
    return http({
      url: '/api/admin/work/save',
      data
    })
  },
  // 详情
  getInfo: (data) => {
    return http({
      url: '/api/admin/work/info',
      data
    })
  },
  // 编辑(修改费用)
  onEdit: (data) => {
    return http({
      url: '/api/admin/work/edit_price',
      data
    })
  },
  // 新增
  onAdd: (data) => {
    return http({
      url: 'api/admin/work/add',
      data
    })
  },
  // 退款
  refund: (data) => {
    return http({
      url: 'api/admin/work/refund',
      data
    })
  },
  // 预处理审核
  pretreatment: (data) => {
    return http({
      url: 'api/admin/work/pretreatment',
      data
    })
  },
  // 审核
  audit: (data) => {
    return http({
      url: 'api/admin/work/audit',
      data
    })
  }
}
