import http from '@/utils/http'
// 用户列表 操作日志
export default {
  // 列表
  getList: (data) => {
    return http({
      url: '/api/admin/user/index',
      data
    })
  },
  // 操作日志
  getAdminLog: (data) => {
    return http({
      url: '/api/admin/admin_log/index',
      data
    })
  }
}
