import http from '@/utils/http'
// 付费管理 // 登记范围
export default {
  // 列表
  getList: (data) => {
    return http({
      url: '/api/admin/registration_scope/index',
      data
    })
  },
  // 删除
  delete: (data) => {
    return http({
      url: '/api/admin/registration_scope/del',
      data
    })
  },
  // 保存
  onSave: (data) => {
    return http({
      url: '/api/admin/registration_scope/save',
      data
    })
  },
  // 详情
  getInfo: (data) => {
    return http({
      url: '/api/admin/registration_scope/info',
      data
    })
  },
  // 排序
  getManualSort: (data) => {
    return http({
      url: '/api/admin/registration_scope/manual_sort',
      data
    })
  },
  // 更新
  updateExcel: (data) => {
    return http({
      url: '/api/admin/registration_scope/create_excel',
      data
    })
  }
}
