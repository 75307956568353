import http from '@/utils/http'
// 认领规则设置
export default {
  // 列表
  getList: (data) => {
    return http({
      url: '/api/admin/article/index',
      data
    })
  },
  // 删除
  delete: (data) => {
    return http({
      url: '/api/admin/article/del',
      data
    })
  },
  // 是否显示
  ifStatus: (data) => {
    return http({
      url: '/api/admin/article/change_status',
      data
    })
  },
  // 修改排序
  updateSort: (data) => {
    return http({
      url: '/api/admin/article/change_sort',
      data
    })
  },
  // 保存
  onSave: (data) => {
    return http({
      url: '/api/admin/article/save',
      data
    })
  },
  // 详情
  getInfo: (data) => {
    return http({
      url: '/api/admin/article/info',
      data
    })
  }
}
