<template>
  <div class="main">
    <div class="cursor-pointer">
      <span style="margin-right: 20px" @click="onHomePage">首页</span>
      <el-tag
        @close="removeRouterTag(index)"
        @click="changeRouterTag(item)"
        style="margin-right: 10px"
        closable
        size="small"
        type="dark"
        :effect="currentRouterTitle == item.title ? 'dark' : 'plain'"
        v-for="(item, index) in openedRouterTagList"
        :key="index"
        >{{ item.title }}</el-tag
      >
    </div>
    <div class="back">
      <el-dropdown>
        <span class="el-dropdown-link backbox">
          <div class="icon"><img src="@/assets/images/head.png" alt="" /></div>
          <div>
            {{ info.admin.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div @click="onBack()">退出登录</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerIndex',
  data () {
    return {
      info: JSON.parse(localStorage.info),
      openedRouterTagList: [],
      currentRouterTitle: ''
    }
  },
  created () {},
  watch: {
    $route (to, from, next) {
      this.changePageStack(to)
    }
  },
  methods: {
    onBack () {
      this.$store.dispatch('user/Logout', 'server').then(() => {})
    },
    onHomePage () {
      this.$router.push({
        path: '/'
      })
    },
    changePageStack (to) {
      console.log('toto', to)
      const { name, query, meta } = to
      const thisRouterIndex = this.openedRouterTagList.findIndex(
        (item) => item.name === name
      )
      if (name === 'home') {
        return false
      }
      // let currentRouterTitle
      if (thisRouterIndex === -1) {
        const { title } = meta
        this.currentRouterTitle = title
        // console.log('currentRouterTitle', this.currentRouterTitle)
        this.openedRouterTagList.push({
          title,
          name
        })
      } else {
        // 已有的 给高亮
        const { title } = meta
        this.currentRouterTitle = title
      }
      const tagLength = this.openedRouterTagList.length
      if (tagLength > 8) {
        this.openedRouterTagList.shift()
      }
      console.log('this.openedRouterTagList', this.openedRouterTagList)
    },
    changeRouterTag (item) {
      this.$router.push({
        name: item.name
      })
      this.currentRouterTitle = item.title
    },
    // 删除
    removeRouterTag (index) {
      console.log('openedRouterTagList', this.openedRouterTagList)
      this.openedRouterTagList.splice(index, 1)
      if (index !== 0) {
        this.$router.push({
          name: this.openedRouterTagList[index - 1].name
        })
        this.currentRouterTitle = this.openedRouterTagList[index - 1].title
      } else {
        this.$router.push({
          name: 'home'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 412px;
  height: 478px;
}
.main {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
  background: #fff;
  padding-left: 33px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
}
.titleBox {
  padding: 5px 15px;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  color: #333333;
}
.back {
  margin-right: 18px;
  font-size: 14px;
  font-weight: 500;
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.backbox {
  display: flex;
  // align-items: center;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.icon {
  padding-top: 5px;
  margin-right: 9px;
  box-sizing: border-box;
}
.el-dropdown-link[data-v-ba109b4c] {
  height: 55px;
}
.dialogMain {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  font-size: 26px;
  line-height: 22px;
  // font-weight: 500;
  color: #6e7487;
  .img {
    width: 90px;
    height: 90px;
    margin-bottom: 4px;
  }
  .buttonBox {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 278px;
    height: 80px;
    padding-left: 18px;
    background: #507afd;
    border-radius: 10px;
    .img {
      width: 44px;
      height: 44px;
    }
    .boxText {
      font-size: 18px;
      // font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>
