import http from '@/utils/http'
// 广告
export default {
  // 列表
  getList: (data) => {
    return http({
      url: '/api/admin/ad/index',
      data
    })
  },
  // 删除
  delete: (data) => {
    return http({
      url: '/api/admin/ad/del',
      data
    })
  },
  // 保存
  onSave: (data) => {
    return http({
      url: '/api/admin/ad/save',
      data
    })
  },
  // 详情
  getInfo: (data) => {
    return http({
      url: '/api/admin/ad/info',
      data
    })
  }
}
