import http from '@/utils/http'
// 认证管理
export default {
  // 列表
  getList: (data) => {
    return http({
      url: '/api/admin/certification/index',
      data
    })
  },
  // 审核
  audit: (data) => {
    return http({
      url: '/api/admin/certification/audit',
      data
    })
  },
  // 保存
  onSave: (data) => {
    return http({
      url: '/api/admin/certification/save',
      data
    })
  },
  // 密码认证
  existAudit: (data) => {
    return http({
      url: '/api/admin/certification/exist_audit',
      data
    })
  },
  // 密码认证
  getInfo: (data) => {
    return http({
      url: '/api/admin/certification/info',
      data
    })
  }
}
