<template>
  <div style="background: #fff">
    <!-- 头部 -->
    <el-container>
      <!-- 左 -->
      <el-aside class="aside">
        <Sidebar></Sidebar>
      </el-aside>
      <el-container class="cont">
        <el-header>
          <top></top>
        </el-header>
        <!-- bread -->
        <bread></bread>
        <div class="row"></div>
        <!-- 右侧main -->
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Sidebar from './components/sidebar.vue'
import top from './components/header.vue'
import bread from './components/bread.vue'
export default {
  name: 'layoutIndex',
  data () {
    return {}
  },
  components: {
    top,
    Sidebar,
    bread
  },
  mounted () {
    // console.log('path', this.$route.path)
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.el-header {
  height: 48px !important;
  padding: 0px;
}
.el-breadcrumb {
  margin-top: 30px;
  /* line-height: 20px; */
}
.aside {
  /* box-sizing: border-box; */
  width: 247px !important;
  padding-top: 30px;
  padding-left: 16px;
  background: #00152A;
}
.cont {
  /* margin-top: 20px; */
  height: 100vh;
  .row {
    height: 10px;
    background: #F0F2F7;
  }
}
.cont .el-main {
  padding: 0%;
  /* margin-left: 10px; */
  // padding: 0 10px;
}
</style>
