import Router from '@/router'
import { accountRole, accountLogin, accountLogout } from '@/api/login'
// import api from '@/api/modules/user.js'
// import api from '@/api/login'

const state = {
  info: localStorage.info, // 用户信息
  // token
  token: localStorage.token
}

const getters = {}

const actions = {
  // 登录
  login ({ commit }, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await accountLogin(data)
        commit('setUserData', res.res)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  // 退出登录 接口
  async Logout ({ commit }, data) {
    console.log('data', data)
    if (data) {
      // await api.logout()
      await accountLogout()
    }
    commit('menu/invalidRoutes', null, { root: true }) // 退出登录调
    commit('removeUserData')
    commit('TagsView/Empty', null, { root: true }) // 未知
    Router.push({
      path: '/login'
    })
  },
  // 获取权限 => 动态路由
  getPermissions ({ commit }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      const { res } = await accountRole()
      console.log('存vuex里账号的权限', res)
      resolve(res)
    })
  }
}

const mutations = {
  setUserData (state, data) {
    localStorage.setItem('info', JSON.stringify(data))
    localStorage.setItem('token', data.token)
    state.info = data
    state.token = data.token
  },
  removeUserData (state) {
    // 退出登录删
    localStorage.removeItem('info')
    localStorage.removeItem('token')
    state.info = ''
    state.token = ''
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
