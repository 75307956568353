<template>
  <div style="background: #00152A">
    <div class="title">广东版权登记中心</div>
    <el-menu
      :default-active="$route.meta.activeMenu || $route.path"
      class="el-menu-vertical-demo, leftMenu"
      background-color="#00152A"
      text-color="#fff"
      active-text-color="#fff"
    >
      <sidebarItem
        v-for="route in $store.getters['menu/sidebarRoutes']"
        :key="route.path"
        :item="route"
        :base-path="route.path"
      ></sidebarItem>
    </el-menu>
  </div>
</template>

<script>
import sidebarItem from './sidebarItem.vue'
export default {
  name: 'SlidebarIndex',
  components: {
    sidebarItem
  },
  data () {
    return {}
  },
  mounted () {
    // console.log('menu', this.$store.getters['menu/sidebarRoutes'])
  }
}
</script>

<style scoped>
.leftMenu {
  width: 215px;
  height: 39px;
}
.el-menu {
  border-right: 0px !important;
}
.title {
  padding-left: 30px;
  margin-bottom: 25px;

  font-size: 20px;
font-weight: bold;
color: #FFFFFF;
}
</style>
